// src/components/PlayButton/PlayButton.js

import React from 'react';
import './PlayButton.css';

const PlayButton = ({ onClick }) => {
  return (
    <button 
      className="play-button" 
      onClick={onClick} 
      aria-label="Play" 
      type="button"  // Ensures this button won't trigger form submission or page jumps
    >
      <span className="play-icon"></span>
    </button>
  );
};

export default PlayButton;
