// src/App.js

import React, { useState, useEffect, useRef } from 'react';
import './styles/App.css';
import TextInputBox from './components/TextInputBox/TextInputBox';
import PlayButton from './components/PlayButton/PlayButton';
import speakerEmbeddings from './constants/speakerEmbeddings';
import { int16ToFloat32 } from './utils/audioUtils';
import logo from './assets/logo.png'; // Import your logo

const WEBSOCKET_URL = 'wss://unicron.ngrok.app/ws';

const App = () => {
  const [text, setText] = useState('');
  const [selectedSpeaker, setSelectedSpeaker] = useState('Lisa'); // Default to 'Lisa'
  const audioContextRef = useRef(null);
  const websocketRef = useRef(null);
  const sourceRef = useRef(null);
  const audioPlayTimeRef = useRef(0);

  const initializeAudioContext = () => {
    if (!audioContextRef.current) {
      const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
      audioContextRef.current = newAudioContext;
    }
  };

  const playAudioData = (arrayBuffer) => {
    initializeAudioContext();
    const audioContext = audioContextRef.current;
    const float32Array = int16ToFloat32(new Int16Array(arrayBuffer));
    const audioBuffer = audioContext.createBuffer(
      1,
      float32Array.length,
      audioContext.sampleRate
    );
    audioBuffer.getChannelData(0).set(float32Array);

    const currentTime = audioContext.currentTime;
    const startTime = Math.max(currentTime, audioPlayTimeRef.current);

    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);
    source.start(startTime);

    audioPlayTimeRef.current = startTime + audioBuffer.duration;
    sourceRef.current = source;
  };

  const onReceiveMessage = (event) => {
    if (event.data instanceof ArrayBuffer) {
      playAudioData(event.data); // Handle binary audio data
    }
  };

  const onError = (error) => {
    console.error('WebSocket error:', error);
  };

  const closeWebSocket = () => {
    if (websocketRef.current) {
      websocketRef.current.close();
      websocketRef.current = null;
    }
  };

  const handlePlayButtonClick = () => {
    if (!text.trim()) {
      alert('Please enter some text before clicking Play.');
      return;
    }

    const speakerEmbedding = speakerEmbeddings[selectedSpeaker];

    closeWebSocket();

    const websocket = new WebSocket(WEBSOCKET_URL);
    websocketRef.current = websocket;
    websocket.binaryType = 'arraybuffer';

    websocket.onopen = () => {
      const requestPayload = {
        text: text,
        speaker_embedding: speakerEmbedding,
        ngrok_skip_browser_warning: '69420', // Include the custom header as part of the payload
      };
      websocket.send(JSON.stringify(requestPayload));
    };

    websocket.onmessage = onReceiveMessage;
    websocket.onerror = onError;
  };

  useEffect(() => {
    return () => {
      closeWebSocket();
    };
  }, []);

  return (
    <div className="app-container">
      <img src={logo} alt="App Logo" className="app-logo" />
      <TextInputBox text={text} setText={setText} />
      <div className="controls-container">
        <select
          value={selectedSpeaker}
          onChange={(e) => setSelectedSpeaker(e.target.value)}
          className="speaker-select"
        >
          {Object.keys(speakerEmbeddings).map((speaker) => (
            <option key={speaker} value={speaker}>
              {speaker}
            </option>
          ))}
        </select>
        <PlayButton onClick={handlePlayButtonClick} />
      </div>
    </div>
  );
};

export default App;
