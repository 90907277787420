// src/components/TextInputBox/TextInputBox.js

import React from 'react';
import './TextInputBox.css';

const TextInputBox = ({ text, setText }) => {
  const handleChange = (event) => {
    setText(event.target.value);
  };

  return (
    <div className="text-input-container">
      <textarea
        className="text-input"
        placeholder="Type your text here..."
        rows="6"
        value={text}
        onChange={handleChange}
      ></textarea>
    </div>
  );
};

export default TextInputBox;
